html {
  overflow: hidden;
}

html, body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: absolute;
  width: 100%;
  overflow-y: scroll;
}

a {
  color: inherit;
}